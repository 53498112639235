<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g id="_81-Arrow_Exchange" data-name="81-Arrow Exchange">
      <path
        stroke="currentColor"
        stroke-width="2px"
        d="M16.71,7.29l-7-7a1,1,0,0,0-1.41,0l-7,7L2.71,8.71,8,3.41V32h2V3.41l5.29,5.29Z"
      />
      <path
        stroke="currentColor"
        stroke-width="2px"
        d="M29.29,23.29,24,28.59V0H22V28.59l-5.29-5.29-1.41,1.41,7,7a1,1,0,0,0,1.41,0l7-7Z"
      />
    </g>
  </svg>
</template>
